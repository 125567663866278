import React from 'react'
import { graphql } from 'gatsby'
import { Container } from 'theme-ui'
import Layout from '@solid-ui-layout/Layout'
import Seo from '@solid-ui-components/Seo'
import Divider from '@solid-ui-components/Divider'
import ModalWithTabs from '@solid-ui-blocks/Modal/Block01'
import Appointment from '@solid-ui-blocks/Modal/Block03'
import Header from '@solid-ui-blocks/Header/Block01'
import Features from '@solid-ui-blocks/Features/Block06'
import WideCTA from '@solid-ui-blocks/Hero/Block03'
import Footer from '@solid-ui-blocks/Footer/Block01'
import Standorte from '@solid-ui-blocks/Features/Standorte'

import { normalizeBlockContentNodes } from '@blocks-helpers'
import styles from './_styles'


const Services01 = props => {
  const { allBlockContent } = props.data
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)

  return (
    <Layout {...props}>
      <Seo
        title='Standorte | Bitconcepts'
        description='Bitconcepts - Agentur für Digitalmarketing, Suchmaschinenwerbung, Webdesign, Social Media und mehr. Erfahre mehr!'
      />
      {/* Modals */}
      <ModalWithTabs content={content['contact']} />
      <Appointment />
      {/* Blocks */}
      <Header content={content['header']} menuJustify='space-between' />
      <Container variant='full' sx={styles.heroContainer}>
        <Features content={content['hero']} />
        <Divider space='4' />
        <Divider space='4' />
      </Container>
      <Divider space='3' />
      <Standorte content={content['standorte']} />
      <Divider space='5' />
      <WideCTA content={content['bottomcta']} />
      <Footer content={content['footer']} />
    </Layout>
  )
}

export const query = graphql`
  query standortequery {
    allBlockContent(
      filter: { page: { in: ["site/standorte", "shared"] } }
    ) {
      nodes {
        ...BlockContent
      }
    }
  }
`

export default Services01

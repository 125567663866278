/**
 *
 * These styles are solely for adding [background images] or
 * [background colors] to blocks.
 *
 */

const styles = {
  heroContainer: {
    position: `relative`,
    pt: [6, 7],
    '::before': {
      position: `absolute`,
      content: `" "`,
      width: `full`,
      height: `100%`,
      top: 0,
      right: 0,
      zIndex: -1,
      bg: `white`
    }
  },
  ansprechpartner: {
    bg: `white`,
    pt: [5, 6],
    pb: [5, 6],
    px: [4, 0]
  },
  preise: {
    bg: `white`,
    pt: [5, 6],
    pb: [5, 6],
    px: [4, 0]
  },
  zwischentext: {
    bg: `white`,
    pb: [5, 6],
    px: [4, 0]
  },
  maintext: {
    bg: `white`,
    pt: [5, 6],
    pb: [5, 6],
    px: [4, 0]
  }
}

export default styles;

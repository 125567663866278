import React from 'react'
import { Container, Flex, Box } from 'theme-ui'
import Reveal from '@solid-ui-components/Reveal'
import Divider from '@solid-ui-components/Divider'
import ContentContainer from '@solid-ui-components/ContentContainer'
import ContentText from '@solid-ui-components/ContentText'
import ContentButtons from '@solid-ui-components/ContentButtons'
import WithDefaultContent from '@solid-ui-blocks/WithDefaultContent'
import ContentImages from '@solid-ui-components/ContentImages'


const styles = {
  avatar: {
    height: `auto`,
    width: `100%`,
    borderTopWidth: `md`,
    borderTopStyle: `solid`,
    borderTopColor: `beta`,
    borderBottomColor: `beta`,
    borderBottomWidth: `md`,
    borderBottomStyle: `solid`,
    boxSizing: `content-box`,
    mx: `auto`,
    mt: `1`,
    img: {
      objectPosition: `top center !important`
    }
  }
}
const FeaturesBlock06 = ({ content: { text = [], collection, buttons } }) => (
  <Container sx={{ textAlign: `center` }}>
    <Box>
      <ContentText content={text} />
    </Box>
    {collection && (
      <>
        <Divider />
          <Flex sx={{ justifyContent: `center`, flexWrap: `wrap`, m: -3 }}>
            {collection.map(({ container, avatar, text }, index) => (
              <Box
                key={`item-${index}`}
                sx={{ flexBasis: [`1`, `1/2`, null, `1/4`], p: 3 }}
              >
                <ContentContainer
                content={container}
                variant='cards.primary'
                sx={{ textAlign: `center`, height: `100%`, p: 2 }}
                >
                  {text?.[1]?.text ? (
                <a href={text?.[1]?.text} rel="noopener noreferrer">
                  <ContentImages
                    content={{ images: [avatar] }}
                    sx={styles.avatar}
                    imageEffect='fadeIn'
                  /></a>
                  ) : (
                    <ContentImages
                    content={{ images: [avatar] }}
                    sx={styles.avatar}
                    imageEffect='fadeIn'
                  />
                  )}
                  <Flex sx={{ flexDirection: `column` }} p='3' px='3'>
                    <ContentText content={text[0]} />
                  </Flex>
                </ContentContainer>
              </Box>
            ))}
          </Flex>
      </>
    )}
    {buttons && (
      <>
        <Divider space={3} />
        <ContentButtons content={buttons} />
      </>
    )}
  </Container>
)

export default WithDefaultContent(FeaturesBlock06)
